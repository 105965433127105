import PropTypes from "prop-types";
import useResponsiveWithHydrationFix from "../../../hooks/useResponsiveWithHydrationFix";

const SectionTitle = ({ title, isTitle = false }) => {
  const headerMobile = isTitle ? (
    <h1
      className="al_h3"
      style={{
        fontSize: `${28}px`,
        lineHeight: `${34}px`,
        letterSpacing: `${0.2}px`,
        marginTop: `${0}px`,
      }}
    >
      {title}
    </h1>
  ) : (
    <h3
      className="al_h3"
      style={{
        fontSize: `${28}px`,
        lineHeight: `${34}px`,
        letterSpacing: `${0.2}px`,
        marginTop: `${0}px`,
      }}
    >
      {title}
    </h3>
  );
  const headerDesktop = isTitle ? (
    <h1 className="al_h2">{title}</h1>
  ) : (
    <h2 className="al_h2">{title}</h2>
  );
  return useResponsiveWithHydrationFix(headerMobile, headerDesktop);
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SectionTitle;
